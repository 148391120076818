var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "p-3" },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _vm.isView
            ? [
                _c(
                  "a-col",
                  { attrs: { flex: "1" } },
                  [
                    _vm.storeAPForm.prepayments.length === 0
                      ? _c("a-empty")
                      : _c("a-list", {
                          attrs: {
                            header: _vm.$t("lbl_applied_prepayment"),
                            "item-layout": "horizontal",
                            "data-source": _vm.storeAPForm.prepayments
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "renderItem",
                                fn: function(item) {
                                  return [
                                    _c(
                                      "a-list-item",
                                      [
                                        _c("a-list-item-meta", {
                                          attrs: {
                                            title: item.invoicePrepaymentNumber,
                                            description: item.description
                                          }
                                        }),
                                        _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  item.appliedAmount
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1788521640
                          )
                        })
                  ],
                  1
                )
              ]
            : [
                _c(
                  "a-col",
                  { attrs: { span: 17 } },
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "mr-3",
                        attrs: { type: "danger", disabled: _vm.allowEdit },
                        on: { click: _vm.resetPrepayments }
                      },
                      [_vm._v(_vm._s(_vm.$t("lbl_reset")))]
                    ),
                    !_vm.storeAPForm.prepayments.length
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("lbl_no_document_selected")) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    _vm._l(_vm.storeAPForm.prepayments, function(doc) {
                      return _c(
                        "a-button",
                        {
                          key: doc.key,
                          staticClass: "ml-2",
                          attrs: { disabled: _vm.allowEdit },
                          on: {
                            click: function($event) {
                              return _vm.handleDeleteRow(doc)
                            }
                          }
                        },
                        [
                          _c("a-icon", {
                            staticStyle: { color: "#f5222d" },
                            attrs: { type: "delete", theme: "filled" }
                          }),
                          _vm._v(
                            " " + _vm._s(doc.invoicePrepaymentNumber) + " "
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                ),
                _c(
                  "a-col",
                  { attrs: { span: 7, align: "end" } },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: {
                          loading: _vm.prepaymentData.loading,
                          icon: "reload"
                        },
                        on: { click: _vm.handleRefresh }
                      },
                      [_vm._v(_vm._s(_vm.$t("lbl_refresh")))]
                    )
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 24 } },
                  [
                    _c("a-table", {
                      attrs: {
                        "data-source": _vm.prepaymentData.data,
                        columns: _vm.columnsTable,
                        scroll: { y: 700 },
                        pagination: {
                          showTotal: function() {
                            return _vm.$t("lbl_total_items", {
                              total: _vm.prepaymentData.totalElements
                            })
                          },
                          total: _vm.prepaymentData.totalElements,
                          showSizeChanger: true,
                          current: _vm.prepaymentData.page,
                          pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                          defaultPageSize: _vm.prepaymentData.limit
                        },
                        "row-class-name": function(_, index) {
                          return index % 2 ? "bg-white" : "bg-gray-light"
                        },
                        loading: _vm.prepaymentData.loading
                      },
                      on: { change: _vm.onChangeTable },
                      scopedSlots: _vm._u([
                        {
                          key: "action",
                          fn: function(text, record) {
                            return [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    disabled: record.disabled || _vm.allowEdit
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.addData(record)
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("lbl_add")) + " ")]
                              )
                            ]
                          }
                        },
                        {
                          key: "footer",
                          fn: function() {
                            return [
                              _c(
                                "table",
                                {
                                  staticStyle: {
                                    width: "400px",
                                    "min-width": "unset"
                                  },
                                  attrs: {
                                    "aria-describedby": "tableTotalPrepayment"
                                  }
                                },
                                [
                                  _c("thead", [
                                    _c("th", { attrs: { colspan: "2" } })
                                  ]),
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(_vm.$t("lbl_total_prepayment"))
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.storeAPForm
                                                .prePaymentUsedTotal
                                            )
                                          )
                                        )
                                      ])
                                    ])
                                  ])
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    })
                  ],
                  1
                )
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }