


















































































































































































































































































































import { SearchBuilder } from "@/builder";
import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectCurrency from "@/components/custom/select/SelectCurrency.vue";
import SelectSupplier from "@/components/custom/select/SelectSupplier.vue";
import SelectTermOfPayment from "@/components/custom/select/SelectTermOfPayment.vue";
import { isNotUnique } from "@/helpers/common";
import { debounceProcess } from "@/helpers/debounce";
import {
  useCoa,
  useContactData,
  useCurrency,
  useFindMasterType,
  usePreferences,
  useProductExpenseAccount,
} from "@/hooks";
import { ApInvoiceMapper } from "@/mapper/ApInvoice.mapper";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DATE_TIME_HOURS_DEFAULT_FORMAT,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { CurrencyCodeEnum, Mode } from "@/models/enums/global.enum";
import { INVOICE_AP_SOURCE } from "@/models/enums/invoice-ap.enum";
import {
  PURCHASE_ORDER_STATUS,
  PURCHASE_ORDER_TYPE,
} from "@/models/enums/purchase-order.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { AccountingAccountResponseDto } from "@/models/interface/accounting-account";
import { AddressDataDto } from "@/models/interface/contact-data";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { PurchaseOrderResponseDto } from "@/models/interface/purchase-order";
import { ReceivingItemDraftResponseDto } from "@/models/interface/receive-item";
import { logisticServices } from "@/services/logistic.service";
import { purchaseServices } from "@/services/purchase.service";
import { receiveItemService } from "@/services/ReceiveItem.service";
import { buildApInvoiceFormState } from "@/store/account-payable/ap-invoice/resource";
import {
  ApInvoiceFormProductState,
  ApInvoiceFormState,
  ApInvoicePrepaymentParams,
} from "@/store/account-payable/ap-invoice/types";
import { LabelInValue } from "@/types";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { FormModel } from "ant-design-vue";
import moment, { Moment } from "moment";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

const SOURCES_TO_GENERATE_FROM_PO: string[] = [INVOICE_AP_SOURCE.SERVICE];

const SOURCES_TO_GENERATE_FROM_GR: string[] = [
  INVOICE_AP_SOURCE.RENT_TO_RENT,
  INVOICE_AP_SOURCE.SPAREPART,
  INVOICE_AP_SOURCE.OTHERS,
  INVOICE_AP_SOURCE.UNIT,
  INVOICE_AP_SOURCE.CAR,
];

@Component({
  components: {
    SelectBranch,
    SelectCurrency,
    SelectSupplier,
    SelectTermOfPayment,
  },
  computed: {
    ...mapState({
      storeAPForm: (st: any) => st.invoiceApStore.formState,
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
    ...mapGetters({
      allowEdit: "invoiceApStore/GET_ALLOW_TO_EDIT",
      documentReferenceNumbers: "invoiceApStore/GET_DOCUMENT_REFERENCE_NUMBER",
    }),
  },
  methods: {
    ...mapMutations({
      setAPForm: "invoiceApStore/setFormState",
    }),
    ...mapActions({
      recalculatePricing: "invoiceApStore/RECALCULATE_PRICING",
      updateNumbering: "invoiceApStore/updateNumbering",
      fetchPrepaymentList: "invoiceApStore/fetchPrepaymentList",
    }),
  },
})
export default class InvoiceAPHeader extends Mixins(MNotificationVue) {
  storeAPForm!: ApInvoiceFormState;
  allowEdit!: boolean;
  setAPForm!: (payload: Partial<ApInvoiceFormState>) => void;
  recalculatePricing!: () => void;
  updateNumbering!: () => void;
  fetchPrepaymentList!: (payload: ApInvoicePrepaymentParams) => void;

  @Ref() readonly formRef!: FormModel;

  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  DATE_TIME_HOURS_DEFAULT_FORMAT = DATE_TIME_HOURS_DEFAULT_FORMAT;

  dataSource: Option[] = [];
  dataDocReference: Option[] = [];
  dataSupplierBillAddress: Option<AddressDataDto>[] = [];
  dataSupplierShipAddress: Option<AddressDataDto>[] = [];

  dataTaxType: Option[] = [];
  dataPayablesAccount: Option<AccountingAccountResponseDto>[] = [];

  mode: Mode | null = null;

  disabled = {
    currency: false,
  };

  queryParams = {
    po: {
      limit: 20,
      page: 0,
      search: `poType~${PURCHASE_ORDER_TYPE.SERVICE}_AND_status~${PURCHASE_ORDER_STATUS.APPROVED}`,
      sorts: "createdDate:desc",
    } as RequestQueryParamsModel,
    gr: {
      limit: 20,
      page: 0,
      search: "",
      sorts: "createdDate:desc",
    } as RequestQueryParamsModel,
  };

  loading = {
    docReference: false,
    source: false,
    taxType: false,
    payableAccount: false,
    generate: false,
  };

  validationSchema = {
    source: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    branch: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    currency: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    supplier: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    supplierShipAddress: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    supplierBillAddress: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    termOfPayment: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    documentReferences: {
      required: true,
      type: "array",
      message: this.$t("lbl_validation_required_error"),
    },
    taxType: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    invoiceSupplierNo: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    invoiceDate: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    invoiceReceivedDate: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    payablesAccount: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    invoiceDescription: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    taxInvoiceDate: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    products: {
      type: "array",
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
  };

  formRules = {
    invoiceType: {
      label: "lbl_invoice_type",
    },
    source: {
      label: "lbl_source",
      name: "source",
      placeholder: "lbl_source_placeholder",
    },
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_branch_placeholder",
    },
    currency: {
      label: "lbl_currency",
      name: "currency",
      placeholder: "lbl_currency_placeholder",
    },
    supplierName: {
      label: "lbl_supplier_name",
      name: "supplierName",
      placeholder: "lbl_supplier_name_placeholder",
    },
    supplierShipAddress: {
      label: "lbl_supplier_ship_address",
      name: "supplierShipAddress",
      placeholder: "lbl_supplier_ship_address_placeholder",
    },
    supplierBillAddress: {
      label: "lbl_supplier_bill_address",
      name: "supplierBillAddress",
      placeholder: "lbl_supplier_bill_address_placeholder",
    },
    termOfPayment: {
      label: "lbl_term_of_payment",
      name: "termOfPayment",
      placeholder: "lbl_term_of_payment_placeholder",
    },
    taxType: {
      label: "lbl_tax_type",
      name: "taxType",
      placeholder: "lbl_tax_type_placeholder",
    },
    invoiceNumber: {
      label: "lbl_invoice_number",
      name: "invoiceNumber",
      placeholder: "lbl_invoice_number_placeholder",
    },
    invoiceSupplierNo: {
      label: "lbl_invoice_supplier_number",
      name: "invoiceSupplierNo",
      placeholder: "lbl_invoice_supplier_number_placeholder",
    },
    invoiceDate: {
      label: "lbl_invoice_date",
      name: "invoiceDate",
      placeholder: "lbl_invoice_date_placeholder",
    },
    accountingDate: {
      label: "lbl_accounting_date",
      name: "accountingDate",
      placeholder: "lbl_accounting_date_placeholder",
    },
    invoiceReceivedDate: {
      label: "lbl_invoice_received_date",
      name: "invoiceReceivedDate",
      placeholder: "lbl_invoice_received_date_placeholder",
    },
    rate: {
      label: "lbl_rate",
      name: "rate",
      placeholder: "lbl_rate_placeholder",
    },
    journalNumber: {
      label: "lbl_journal_number",
      name: "journalNumber",
      placeholder: "lbl_journal_number_placeholder",
    },
    payablesAccount: {
      label: "lbl_payables_account",
      name: "payablesAccount",
      placeholder: "lbl_payables_account_placeholder",
    },
    invoiceDescription: {
      label: "lbl_invoice_description",
      name: "invoiceDescription",
      placeholder: "lbl_invoice_description_placeholder",
    },
    statusInvoice: {
      label: "lbl_status_invoice",
      name: "statusInvoice",
      placeholder: "lbl_status_invoice_placeholder",
    },
  };

  created(): void {
    this.onSearchDocReference = debounceProcess(this.onSearchDocReference);
    this.onSearchPayableAccount = debounceProcess(this.onSearchPayableAccount);

    this.mode = this.$route.meta.mode as Mode;

    if (this.isModeCreate) {
      this.setBaseCurrencyCreate();

      if (this.isIDR) {
        this.setBasePayablesAccount();
      }
    } else {
      this.deciderGetListDocumentSource();
    }

    this.fetchOptions();
  }

  get isIDR(): boolean {
    return (
      this.storeAPForm.currency?.label.trim().toUpperCase() ===
      CurrencyCodeEnum.IDR
    );
  }

  get documentReferenceLabel(): string {
    if (this.shouldGenerateFromPO) {
      return this.$t("lbl_purchase_order").toString();
    } else {
      return this.$t("lbl_goods_receipt").toString();
    }
  }

  get shouldGenerateFromPO(): boolean {
    return this.storeAPForm.source
      ? SOURCES_TO_GENERATE_FROM_PO.includes(this.storeAPForm.source)
      : false;
  }

  get shouldGenerateFromGR(): boolean {
    return this.storeAPForm.source
      ? SOURCES_TO_GENERATE_FROM_GR.includes(this.storeAPForm.source)
      : false;
  }

  get isModeCreate(): boolean {
    return this.mode === Mode.CREATE;
  }

  fetchOptions(): void {
    this.getSource();
    this.getTaxType();

    if (this.storeAPForm.currency) {
      const params = new RequestQueryParams();
      params.search = new SearchBuilder()
        .push(["currency.currencyCode", this.storeAPForm.currency.label])
        .build();
      this.getPayablesAccount(params);
    }
  }

  handleChangeSource(value: string): void {
    const { findBaseCurrency } = usePreferences();
    const init = buildApInvoiceFormState();
    const preference = findBaseCurrency();
    const currency =
      preference && preference.value
        ? { key: preference.value, label: preference.name }
        : undefined;
    this.setAPForm({
      ...init,
      currency,
      source: value,
      prepayments: [...this.storeAPForm.prepayments],
      deletedPrepaymentIds: [...this.storeAPForm.deletedPrepaymentIds],
    });
    this.setBasePayablesAccount();

    this.recalculatePricing();
    this.disabled.currency = false;
  }

  handleChangeCurrency(val?: LabelInValue): void {
    this.setAPForm({
      payablesAccount: undefined,
      currencyRates: 1,
    });

    if (!val) {
      this.dataPayablesAccount = [];
      this.dataDocReference = [];
      return;
    }
    const { toLabel } = useCoa();
    const isUsingIdr = val.label.trim().toUpperCase() === CurrencyCodeEnum.IDR;
    if (isUsingIdr) {
      this.setBasePayablesAccount();
    } else {
      this.findCurrencyRates(val.label.trim());
    }

    const params = new RequestQueryParams();
    params.search = new SearchBuilder()
      .push(["currency.currencyCode", val.label])
      .build();
    this.getPayablesAccount(params, payload => {
      if (!payload.length || isUsingIdr) return;
      this.setAPForm({
        payablesAccount: {
          key: payload[0].id,
          label: toLabel({
            code: payload[0].code,
            desc: payload[0].description,
          }),
        },
      });
    });

    this.fetchPrepaymentList({
      branchId: this.storeAPForm.branch?.key,
      supplierId: this.storeAPForm.supplier?.key,
      currencyCode: val?.label,
    });
    this.deciderGetListDocumentSource();
  }

  /**
   * find rates of currency
   * @param toCurr currency based on preference
   */
  findCurrencyRates(toCurr: string): void {
    const { findBaseCurrency } = usePreferences();
    const { findConversion } = useCurrency();
    const baseCurrency = findBaseCurrency();
    if (!baseCurrency) return;
    findConversion(toCurr, baseCurrency.name).then(response => {
      const [curr] = response.data;
      if (!curr) return;
      this.setAPForm({
        currencyRates: curr.rate ?? 1,
      });
    });
  }

  getSource(): void {
    this.loading.source = true;
    useFindMasterType("INVOICE_AP_SOURCE")
      .then(response => {
        this.dataSource = response.map(item => ({
          label: item.value,
          key: item.value,
          value: item.value,
        }));
      })
      .finally(() => (this.loading.source = false));
  }

  handleChangeSupplier(val?: LabelInValue): void {
    this.setAPForm({
      products: [],
      deletedLineIds: [],
      prepayments: [],
      documentReferences: [],
      supplierBillAddress: undefined,
      supplierShipAddress: undefined,
      termOfPayment: undefined,
      taxRegistrationNumber: undefined,
      taxRegistrationName: undefined,
    });

    if (val) {
      this.getDetailContact(val.key);
    }

    this.recalculatePricing();
    this.deciderGetListDocumentSource();
    this.fetchPrepaymentList({
      branchId: this.storeAPForm.branch?.key,
      supplierId: val?.key,
      currencyCode: this.storeAPForm.branch?.key,
    });
  }

  getDetailContact(supplierId: string): void {
    const {
      findOne,
      getDefaultShipToAddress,
      getDefaultBillToAddress,
      toBillToAddressOptions,
      toShipToAddressOptions,
    } = useContactData();
    findOne(supplierId).then(detail => {
      this.setAPForm({
        termOfPayment: detail.top ? String(detail.top) : undefined,
        taxRegistrationNumber: detail.taxRegisNumber ?? undefined,
        taxRegistrationName: detail.taxRegisName ?? undefined,
      });
      if (detail.addressDataList) {
        this.setAPForm({
          supplierBillAddress: getDefaultBillToAddress(detail.addressDataList),
          supplierShipAddress: getDefaultShipToAddress(detail.addressDataList),
        });
        this.dataSupplierBillAddress = toBillToAddressOptions(
          detail.addressDataList
        );
        this.dataSupplierShipAddress = toShipToAddressOptions(
          detail.addressDataList
        );
      }
    });
  }

  searchPO(value?: string): void {
    const searchBy: string[] = [this.createQuerySearch()];

    if (value) {
      searchBy.unshift(`documentNumber~*${value}*`);
    }

    this.queryParams.po.search = searchBy.join("_AND_");

    this.getListPO(this.queryParams.po);
  }

  getListPO(params: RequestQueryParamsModel): void {
    this.loading.docReference = true;
    purchaseServices
      .getListPurchaseOrderAvailableAP(params)
      .then(response => {
        this.dataDocReference = response.data
          .filter(dataFilter => dataFilter.documentNumber)
          .map<Option>(item => ({
            label: item.documentNumber,
            value: item.id,
            key: item.id,
          }));
      })
      .finally(() => (this.loading.docReference = false));
  }

  onSearchDocReference(value?: string): void {
    if (this.shouldGenerateFromGR) {
      this.searchGR(value);
    } else {
      this.searchPO(value);
    }
  }

  searchGR(value?: string): void {
    const searchBy: string[] = [this.createQuerySearch()];

    if (value) {
      searchBy.unshift(`receiveNumber~*${value}*`);
    }

    this.queryParams.gr.search = searchBy.join("_AND_");

    this.getListGR(this.queryParams.gr);
  }

  getListGR(params: RequestQueryParamsModel): void {
    this.loading.docReference = true;
    logisticServices
      .listOfReceivingItemsAvailableInvoiceAp(params)
      .then(response => {
        this.dataDocReference = response.data.map<Option>(item => ({
          label: item.receiveNumber,
          value: item.id,
          key: item.id,
        }));
      })
      .finally(() => (this.loading.docReference = false));
  }

  getTaxType(): void {
    this.loading.taxType = true;
    useFindMasterType("TAX_CALCULATION")
      .then(res => {
        this.dataTaxType = res
          .filter(item => item.value !== TAX_CALCULATION.NONE)
          .map(item => ({
            label: item.value,
            value: item.value,
            key: item.value,
          }));
      })
      .finally(() => (this.loading.taxType = false));
  }

  getPayablesAccount(
    params: RequestQueryParamsModel = new RequestQueryParams(),
    callback?: (payload: AccountingAccountResponseDto[]) => void
  ): void {
    const { findList, toOptions } = useCoa();

    const findByParentAccount = new SearchBuilder()
      .push(["parentAccount.code", "2"], { like: "end" })
      .build();
    const queries: string[] = [findByParentAccount];

    if (params.search) {
      queries.unshift(params.search);
    }

    params.search = queries.join(SearchBuilder.AND);
    params.sorts = "code:asc";

    this.loading.payableAccount = true;
    findList(params)
      .then(response => {
        this.dataPayablesAccount = toOptions(response.data);
        if (callback) {
          callback(response.data);
        }
      })
      .finally(() => {
        this.loading.payableAccount = false;
      });
  }

  handleGenerate(): void {
    if (!this.storeAPForm.documentReferences) return;
    let newDocReferenceIds: string[] = [];

    const docReferenceIds = this.storeAPForm.documentReferences.map(
      item => item.key
    );
    const productDocReferenceIds = this.storeAPForm.products.map(
      product => product.documentReferenceId
    );

    if (this.storeAPForm.products.length === 0) {
      newDocReferenceIds = docReferenceIds;
    } else {
      newDocReferenceIds = docReferenceIds.filter(
        id => !productDocReferenceIds.includes(id)
      );
    }

    if (this.shouldGenerateFromGR) {
      this.generateFromGR(newDocReferenceIds);
    } else {
      this.generateFromPO(newDocReferenceIds);
    }
  }

  setBaseCurrencyCreate(): void {
    const { findBaseCurrency } = usePreferences();
    const val = findBaseCurrency();
    if (!val || !val.value) return;
    this.setAPForm({
      currency: {
        key: val.value,
        label: val.name,
      },
    });
  }

  setBasePayablesAccount(): void {
    const { findByKey } = usePreferences();
    const val = findByKey("account_setup_account_payables");
    if (!val || !val.value) return;
    this.setAPForm({
      payablesAccount: {
        key: val.value,
        label: val.name,
      },
    });
  }

  /**
   * set default expense account on each line
   * based on preference
   */
  async setDefaultExpenseAccount(): Promise<void> {
    const { source, products } = this.storeAPForm;
    if (source === INVOICE_AP_SOURCE.SERVICE) {
      // use expense account from master product if come from PO service
      const promises: Promise<{
        expensePurchaseAccountId: string;
        expensePurchaseAccountName: string;
      }>[] = [];
      products.forEach(product => {
        if (product.partName) {
          promises.push(useProductExpenseAccount(product.partName.key));
        }
      });
      try {
        const responses = await Promise.all(promises);
        const filtered = responses.filter(
          response => !!response.expensePurchaseAccountId
        );
        filtered.forEach((response, index) => {
          if (products[index].partName) {
            products[index].expenseAccount = {
              key: response.expensePurchaseAccountId,
              label: response.expensePurchaseAccountName,
            };
          }
        });
      } catch (error) {
        this.showNotifError("notif_process_fail");
      }
    } else {
      // use unbilled payables account if come from GR
      const { findByKey } = usePreferences();
      const account = findByKey("account_setup_unbilled_payables");
      if (!account || !account.value) return;
      products.forEach(product => {
        product.expenseAccount = {
          key: account.value,
          label: account.name,
        };
      });
    }
  }

  onChangeTaxType(): void {
    this.recalculatePricing();
  }

  onChangeInvoiceDate(e: Moment): void {
    this.setAPForm({
      accountingDate: e,
    });
  }

  disabledDateInvoiceReceived(current: string): boolean {
    return moment(current).isBefore(this.storeAPForm.invoiceDate);
  }

  guardTaxType(
    data: Array<ReceivingItemDraftResponseDto | PurchaseOrderResponseDto>
  ): boolean {
    const taxTypes = data.map<string>(item => item.taxType);
    return isNotUnique(taxTypes);
  }

  generateFromGR(ids: string[]): void {
    const promises: Promise<ReceivingItemDraftResponseDto>[] = [];
    ids.forEach(id => {
      promises.push(receiveItemService.getDetailDraftReceiveItem(id));
    });

    Promise.all(promises)
      .then(details => {
        if (this.guardTaxType(details)) {
          this.showNotifWarning("notif_document_has_different_tax_type");
          return;
        }

        details.forEach(detail => {
          const lines: ApInvoiceFormProductState[] = detail.receiveItems
            .filter(item => item.qtyOutstanding > 0)
            .map(item => {
              const state = ApInvoiceMapper.toApInvoiceFormProductState(item);
              state.documentReference = detail.receiveNumber;
              state.documentReferenceId = detail.id;
              return state;
            });
          this.storeAPForm.products.push(...lines);
        });

        this.setAPForm({
          taxType: details[0]?.taxType,
        });

        this.updateNumbering();

        this.setDefaultExpenseAccount();
        this.recalculatePricing();

        this.disabled.currency = true;
      })
      .catch(() => (this.disabled.currency = false));
  }

  generateFromPO(ids: string[]): void {
    const promises: Promise<PurchaseOrderResponseDto>[] = [];
    ids.forEach(id => {
      promises.push(purchaseServices.getDetailPurchaseOrderNew(id));
    });

    Promise.all(promises)
      .then(details => {
        if (this.guardTaxType(details)) {
          this.showNotifWarning("notif_document_has_different_tax_type");
          return;
        }

        details.forEach(detail => {
          const lines: ApInvoiceFormProductState[] = detail.purchaseOrderLines
            .filter(item => item.qtyOutstanding > 0)
            .map(item => {
              const state = ApInvoiceMapper.toApInvoiceFormProductState(item);
              state.documentReference = detail.documentNumber;
              state.documentReferenceId = detail.id;
              return state;
            });
          this.storeAPForm.products.push(...lines);
        });

        this.setAPForm({
          taxType: details[0]?.taxType,
        });

        this.updateNumbering();
        this.setDefaultExpenseAccount();
        this.recalculatePricing();

        this.disabled.currency = true;
      })
      .catch(() => (this.disabled.currency = false));
  }

  onDeselectDocReference({ key: docRefId }: LabelInValue): void {
    const newSource: ApInvoiceFormProductState[] = [];
    this.storeAPForm.products.forEach(row => {
      if (row.id && row.documentReferenceId === docRefId) {
        // this.pushDeletedInvoiceAPLine(row.id);
        this.storeAPForm.deletedLineIds.push(row.id);
      }
      if (row.documentReferenceId !== docRefId) {
        newSource.push(row);
      }
    });
    this.setAPForm({ products: newSource });
    this.updateNumbering();
    this.recalculatePricing();
  }

  handleChangeBranch(value?: LabelInValue): void {
    this.deciderGetListDocumentSource();
    this.fetchPrepaymentList({
      branchId: value?.key,
      supplierId: this.storeAPForm.supplier?.key,
      currencyCode: this.storeAPForm.currency?.label,
    });
  }

  /**
   * create query search for finding PO/GR
   * based on supplier, branch, and currency
   */
  createQuerySearch(): string {
    const searchBy: string[] = [];
    const { supplier, branch, currency } = this.storeAPForm;

    if (supplier) {
      searchBy.push(`supplier.secureId~${supplier.key}`);
    }

    if (branch) {
      searchBy.push(`branch.secureId~${branch.key}`);
    }

    if (currency) {
      searchBy.push(`priceCurrency.currencyCode~${currency.label}`);
    }

    return searchBy.join("_AND_");
  }

  deciderGetListDocumentSource(): void {
    const { supplier, branch, currency } = this.storeAPForm;

    if (!supplier || !branch || !currency) return;

    // find PO/GR by supplier
    if (this.shouldGenerateFromPO) {
      this.queryParams.po.search = this.createQuerySearch();
      this.getListPO(this.queryParams.po);
    } else if (this.shouldGenerateFromGR) {
      this.queryParams.gr.search = this.createQuerySearch();
      this.getListGR(this.queryParams.gr);
    }
  }

  disabledDate(current: Moment): boolean {
    if (this.isModeCreate) {
      return current > moment();
    }
    const year = moment(this.storeAPDetail.invoiceDate).year();
    const JANUARY = 0;
    const DECEMBER = 11;
    const backYear = moment().year(year).month(JANUARY).date(1).startOf("day");
    const afterYear = moment().year(year).month(DECEMBER).date(31).endOf("day");
    return current < backYear || current > afterYear;
  }

  onSearchPayableAccount(val?: string): void {
    const params = new RequestQueryParams();
    if (val) {
      params.search = new SearchBuilder()
        .push(["code", val], { like: "both" })
        .or()
        .push(["description", val], { like: "both" })
        .build();
    }
    this.getPayablesAccount(params);
  }
}
