

































import Decimal from "decimal.js-light";
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters({
      getTotalGross: "salesOrderStore/getTotalGross",
      getTotalTax: "salesOrderStore/getTotalTax",
      getTotalDiscount: "salesOrderStore/getTotalDiscount",
      getGrandTotal: "salesOrderStore/getGrandTotal",
    }),
  },
})
export default class DisplayTotal extends Vue {
  getTotalGross!: number;
  getTotalTax!: Decimal;
  getTotalDiscount!: number;
  getGrandTotal!: number;

  get totalTax(): number {
    return this.getTotalTax.toNumber();
  }
}
