var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "p-3" },
    [
      _c(
        "a-form-model-item",
        { attrs: { label: _vm.$t(_vm.formRules.taxRegistrationNumber.label) } },
        [
          _c("a-input", {
            attrs: {
              value: _vm.formState.taxRegistrationNumber,
              name: _vm.formRules.taxRegistrationNumber.name,
              placeholder: _vm.$t(
                _vm.formRules.taxRegistrationNumber.placeholder
              ),
              autocomplete: "off",
              disabled: true
            }
          })
        ],
        1
      ),
      _c(
        "a-form-model-item",
        { attrs: { label: _vm.$t(_vm.formRules.taxRegistrationName.label) } },
        [
          _c("a-input", {
            attrs: {
              value: _vm.formState.taxRegistrationName,
              name: _vm.formRules.taxRegistrationName.name,
              placeholder: _vm.$t(
                _vm.formRules.taxRegistrationName.placeholder
              ),
              autocomplete: "off",
              disabled: true
            }
          })
        ],
        1
      ),
      _c(
        "a-form-model-item",
        {
          attrs: {
            label: _vm.$t(_vm.formRules.taxInvoiceDate.label),
            prop: "taxInvoiceDate"
          }
        },
        [
          _c("a-date-picker", {
            staticClass: "w-100",
            attrs: {
              name: _vm.formRules.taxInvoiceDate.name,
              placeholder: _vm.$t(_vm.formRules.taxInvoiceDate.placeholder),
              format: _vm.DATE_TIME_HOURS_DEFAULT_FORMAT,
              disabled: _vm.allowEdit,
              "show-time": ""
            },
            model: {
              value: _vm.formState.taxInvoiceDate,
              callback: function($$v) {
                _vm.$set(_vm.formState, "taxInvoiceDate", $$v)
              },
              expression: "formState.taxInvoiceDate"
            }
          })
        ],
        1
      ),
      _c(
        "a-form-model-item",
        { attrs: { label: _vm.$t(_vm.formRules.taxInvoiceNumber.label) } },
        [
          _c("a-input", {
            attrs: {
              name: _vm.formRules.taxInvoiceNumber.name,
              placeholder: _vm.$t(_vm.formRules.taxInvoiceNumber.placeholder),
              autocomplete: "off",
              disabled: _vm.allowEdit
            },
            model: {
              value: _vm.formState.taxInvoiceNumber,
              callback: function($$v) {
                _vm.$set(_vm.formState, "taxInvoiceNumber", $$v)
              },
              expression: "formState.taxInvoiceNumber"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }