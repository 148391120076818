















































































































import currencyFilter from "@/filters/currency.filter";
import { generateUUID } from "@/helpers/uuid";
import MNotificationVue from "@/mixins/MNotification.vue";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { Mode } from "@/models/enums/global.enum";
import {
  ApInvoiceFormState,
  ApInvoicePrepaymentData,
  ApInvoicePrepaymentDataState,
  ApInvoicePrepaymentParams,
  ApInvoicePrepaymentState,
} from "@/store/account-payable/ap-invoice/types";
import { Component, Mixins } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

@Component({
  computed: {
    ...mapState({
      storeAPForm: (st: any) => st.invoiceApStore.formState,
      prepaymentData: (st: any) => st.invoiceApStore.prepaymentData,
    }),
    ...mapGetters({
      allowEdit: "invoiceApStore/GET_ALLOW_TO_EDIT",
    }),
  },
  methods: {
    ...mapMutations({
      setFormState: "invoiceApStore/setFormState",
    }),
    ...mapActions({
      pushDeletedPrepayment:
        "invoiceApStore/PUSH_DELETED_INVOICE_AP_PREPAYMENTS",
      recalculatePricing: "invoiceApStore/RECALCULATE_PRICING",
      fetchPrepaymentList: "invoiceApStore/fetchPrepaymentList",
    }),
  },
})
export default class TabApplyPrepaymentInvoiceAp extends Mixins(
  MNotificationVue
) {
  storeAPForm!: ApInvoiceFormState;
  prepaymentData!: ApInvoicePrepaymentDataState;
  setFormState!: (payload: Partial<ApInvoiceFormState>) => void;
  fetchPrepaymentList!: (payload: ApInvoicePrepaymentParams) => void;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  columnsTable = [
    {
      title: this.$t("lbl_no"),
      dataIndex: "no",
      key: "no",
      width: 90,
      scopedSlots: { customRender: "no" },
    },
    {
      title: this.$t("lbl_document_number"),
      dataIndex: "prepaymentNumber",
      key: "invoiceNumber",
      width: 200,
    },
    {
      title: this.$t("lbl_amount"),
      dataIndex: "prepaymentTotal",
      key: "amount",
      width: 150,
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "prepaymentDescription",
      key: "description",
      customRender: text => text || "-",
    },
    {
      key: "action",
      scopedSlots: { customRender: "action" },
    },
  ];

  get isView(): boolean {
    return this.$route.meta.mode === Mode.VIEW;
  }

  created(): void {
    this.fetchPrepaymentList({
      branchId: this.storeAPForm.branch?.key,
      currencyCode: this.storeAPForm.currency?.label,
      supplierId: this.storeAPForm.supplier?.key,
    });
  }

  handleDeleteRow(doc: ApInvoicePrepaymentState): void {
    this.$confirm({
      title: this.$t("lbl_modal_delete_title_confirm").toString(),
      onOk: () => this.deletePrepayment(doc),
      onCancel() {
        return;
      },
    });
  }

  addData(row: ApInvoicePrepaymentData): void {
    const doc = this.storeAPForm.prepayments.find(
      item => item.invoicePrepaymentId === row.prepaymentId
    );
    if (doc) {
      this.showNotifError(this.$t("notif_error_duplicate_data").toString());
      return;
    }

    const prepayment: ApInvoicePrepaymentState = {
      appliedAmount: row.prepaymentTotal,
      description: row.prepaymentDescription,
      invoicePrepaymentId: row.prepaymentId,
      invoicePrepaymentNumber: row.prepaymentNumber,
      key: generateUUID(),
    };
    const prepayments: ApInvoicePrepaymentState[] = [
      ...this.storeAPForm.prepayments,
      prepayment,
    ];

    this.setFormState({ prepayments });
    this.recalculatePricing();
  }

  deletePrepayment(doc: ApInvoicePrepaymentState): void {
    const newPrepayments = this.storeAPForm.prepayments.filter(
      item => item.invoicePrepaymentId !== doc.invoicePrepaymentId
    );
    if (doc.secureId) {
      this.setFormState({
        deletedPrepaymentIds: [
          ...this.storeAPForm.deletedPrepaymentIds,
          doc.secureId,
        ],
      });
    }
    this.setFormState({
      prepayments: newPrepayments,
    });
    this.recalculatePricing();
  }

  resetPrepayments(): void {
    this.setFormState({
      prepayments: [],
    });
  }

  handleRefresh(): void {
    this.prepaymentData.page = 1;
    this.fetchPrepaymentList({
      branchId: this.storeAPForm.branch?.key,
      currencyCode: this.storeAPForm.currency?.label,
      supplierId: this.storeAPForm.supplier?.key,
    });
  }

  onChangeTable(pagination: {
    total: number;
    current: number;
    pageSize: number;
  }): void {
    const { current, pageSize } = pagination;
    this.prepaymentData.page =
      pageSize !== this.prepaymentData.limit ? 1 : current;
    this.prepaymentData.limit = pageSize;
    this.fetchPrepaymentList({
      branchId: this.storeAPForm.branch?.key,
      currencyCode: this.storeAPForm.currency?.label,
      supplierId: this.storeAPForm.supplier?.key,
    });
  }
}
