var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "p-3" },
    [
      _c(
        "a-form-model-item",
        _vm._b(
          { attrs: { label: _vm.$t(_vm.formRules.total.label) } },
          "a-form-model-item",
          _vm.wrapper,
          false
        ),
        [
          _c("a-input-number", {
            staticClass: "w-100",
            attrs: {
              value: _vm.formState.grandTotal,
              name: _vm.formRules.total.name,
              disabled: true,
              formatter: _vm.formatterNumber,
              parser: _vm.reverseFormatNumber,
              min: 0,
              precision: _vm.storeBaseDecimalPlace
            }
          })
        ],
        1
      ),
      _c(
        "a-form-model-item",
        _vm._b(
          { attrs: { label: _vm.$t(_vm.formRules.prepaymentUsed.label) } },
          "a-form-model-item",
          _vm.wrapper,
          false
        ),
        [
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { flex: "1" } },
                [
                  _c("a-input-number", {
                    staticClass: "w-100",
                    attrs: {
                      value: _vm.formState.prePaymentUsedTotal,
                      name: _vm.formRules.prepaymentUsed.name,
                      disabled: true,
                      formatter: _vm.formatterNumber,
                      parser: _vm.reverseFormatNumber,
                      min: 0,
                      precision: _vm.storeBaseDecimalPlace
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "link" },
                      on: {
                        click: function($event) {
                          return _vm.handleClickMore("prepaymentUsed")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_more")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-form-model-item",
        _vm._b(
          {
            attrs: { label: _vm.$t(_vm.formRules.remainingInvoiceAmount.label) }
          },
          "a-form-model-item",
          _vm.wrapper,
          false
        ),
        [
          _c("a-input-number", {
            staticClass: "w-100",
            attrs: {
              value: _vm.formState.remainingInvoiceAmount,
              name: _vm.formRules.remainingInvoiceAmount.name,
              disabled: true,
              formatter: _vm.formatterNumber,
              parser: _vm.reverseFormatNumber,
              min: 0,
              precision: _vm.storeBaseDecimalPlace
            }
          })
        ],
        1
      ),
      _c(
        "a-form-model-item",
        _vm._b(
          { attrs: { label: _vm.$t(_vm.formRules.payment.label) } },
          "a-form-model-item",
          _vm.wrapper,
          false
        ),
        [
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { flex: "1" } },
                [
                  _c("a-input-number", {
                    staticClass: "w-100",
                    attrs: {
                      value: _vm.formState.paymentTotal,
                      name: _vm.formRules.payment.name,
                      disabled: true,
                      formatter: _vm.formatterNumber,
                      parser: _vm.reverseFormatNumber,
                      min: 0,
                      precision: _vm.storeBaseDecimalPlace
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "link" },
                      on: {
                        click: function($event) {
                          return _vm.handleClickMore("payment")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_more")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-form-model-item",
        _vm._b(
          { attrs: { label: _vm.$t(_vm.formRules.return.label) } },
          "a-form-model-item",
          _vm.wrapper,
          false
        ),
        [
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { flex: "1" } },
                [
                  _c("a-input-number", {
                    staticClass: "w-100",
                    attrs: {
                      name: _vm.formRules.return.name,
                      disabled: true,
                      formatter: _vm.formatterNumber,
                      parser: _vm.reverseFormatNumber,
                      min: 0,
                      precision: _vm.storeBaseDecimalPlace
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "link" },
                      on: {
                        click: function($event) {
                          return _vm.handleClickMore("return")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_more")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            centered: false,
            dialogstyle: { top: "20px" },
            maskClosable: false,
            width: 820,
            title:
              _vm.titleModal === "prepaymentUsed"
                ? _vm.$t("common.detail-text", {
                    text: _vm.$t("lbl_prepayment_used")
                  })
                : _vm.titleModal === "payment"
                ? _vm.$t("common.detail-text", { text: _vm.$t("lbl_payment") })
                : _vm.$t("common.detail-text", { text: _vm.$t("lbl_returns") }),
            showmodal: _vm.visibleModal,
            idmodal: "modal1"
          },
          scopedSlots: _vm._u([
            {
              key: "contentmodal",
              fn: function() {
                return [
                  _c("a-table", {
                    attrs: {
                      dataSource:
                        _vm.titleModal === "prepaymentUsed"
                          ? _vm.dataSourcePrepaymentUsed
                          : _vm.titleModal === "payment"
                          ? _vm.dataSourceApPayment
                          : _vm.dataSourceReturn,
                      columns:
                        _vm.titleModal === "prepaymentUsed"
                          ? _vm.columnsTablPrepaymentUsed
                          : _vm.titleModal === "payment"
                          ? _vm.columnsTableApPayment
                          : _vm.columnsTableReturn,
                      size: "small",
                      "row-class-name": function(_, index) {
                        return index % 2 ? "bg-white" : "bg-gray-light"
                      }
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "div",
            { attrs: { slot: "footermodal" }, slot: "footermodal" },
            [
              _c(
                "a-button",
                { key: "back", on: { click: _vm.handleCloseModal } },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }