import DisplayTotal from "./DisplayTotal.vue";
import FormTable from "./FormTable.vue";
import ModalCloseSo from "./ModalCloseSo.vue";
import SelectAvailableAsset from "./SelectAvailableAsset.vue";
import SelectLocationInventory from "./SelectLocationInventory.vue";
import SelectProductInStock from "./SelectProductInStock.vue";
import SelectProductService from "./SelectProductService.vue";
import SelectSalesType from "./SelectSalesType.vue";
import SelectSnSales from "./SelectSnSales.vue";

export {
  DisplayTotal,
  FormTable,
  ModalCloseSo,
  SelectAvailableAsset,
  SelectLocationInventory,
  SelectProductInStock,
  SelectProductService,
  SelectSalesType,
  SelectSnSales,
};
