



































































































import { validateTable } from "@/helpers/common";
import { ApInvoiceMapper } from "@/mapper/ApInvoice.mapper";
import MNotificationVue from "@/mixins/MNotification.vue";
import MValidationVue from "@/mixins/MValidationTranslate.vue";
import { Mode } from "@/models/enums/global.enum";
import { INVOICE_AP_STATUS } from "@/models/enums/invoice-ap.enum";
import {
  InvoiceApCreateDto,
  InvoiceApResponseDetailDto,
  InvoiceApUpdateDto,
} from "@/models/interface/ap-invoice";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { invoiceAPServices } from "@/services/invoiceAp.service";
import {
  ApInvoiceFormProductState,
  ApInvoiceFormState,
} from "@/store/account-payable/ap-invoice/types";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";
import InvoiceApHeader from "./InvoiceAPHeader.vue";
import TabApplyPrepaymentInvoiceAp from "./TabApplyPrepaymentInvoiceAp.vue";
import TabDetailsInvoiceAp from "./TabDetailsInvoiceAp.vue";
import TabStatusInvoiceAp from "./TabStatusInvoiceAp.vue";
import TabTaxDetailsInvoiceAp from "./TabTaxDetailsInvoiceAp.vue";

type SubmitType = "update" | "submit" | "approve" | "draft";

const ALLOW_TO_SAVE_DRAFT: string[] = [INVOICE_AP_STATUS.DRAFT];

const ALLOW_TO_UPDATE: string[] = [
  INVOICE_AP_STATUS.DRAFT,
  INVOICE_AP_STATUS.NEED_APPROVAL,
];

const ALLOW_TO_APPROVE: string[] = [INVOICE_AP_STATUS.NEED_APPROVAL];

const ALLOW_TO_CANCEL: string[] = [
  INVOICE_AP_STATUS.DRAFT,
  INVOICE_AP_STATUS.UNPAID,
];

const ALLOW_TO_SUBMIT: string[] = [...ALLOW_TO_UPDATE];

const FORM_TRANSLATE_KEY = {
  source: "lbl_source",
  supplier: "lbl_supplier_name",
  supplierShipAddress: "lbl_supplier_ship_address",
  supplierBillAddress: "lbl_supplier_bill_address",
  termOfPayment: "lbl_term_of_payment",
  invoiceSupplierNo: "lbl_invoice_supplier_number",
  invoiceDate: "lbl_invoice_date",
  accountingDate: "lbl_accounting_date",
  invoiceReceivedDate: "lbl_invoice_received_date",
  payablesAccount: "lbl_payables_account",
  invoiceDescription: "lbl_invoice_description",
  taxInvoiceDate: "lbl_tax_invoice_date",
  branch: "lbl_branch",
  currency: "lbl_currency",
};

const TABLE_TRANSLATE_KEY = {
  expenseAccount: "lbl_expense_account",
  brand: "lbl_brand",
  partName: "lbl_part_name",
  partNumber: "lbl_part_number",
  price: "lbl_price",
  qty: "lbl_qty",
  taxCode: "lbl_tax_code",
  uom: "lbl_uom",
};

const REQUIRED_TABLE_COLUMN = [
  "expenseAccount",
  "brand",
  "partName",
  "partNumber",
  "price",
  "qty",
  "taxCode",
  "uom",
];

/**
 * TODO:
 * 1. integrasi tab status
 * - return invoice -> total return amount dan lines [ nunggu backend buat return supplier dulu ]
 * 2. nambahin rule buat munculin pph berdasarkan field source [SPP-1950]
 */

@Component({
  components: {
    InvoiceApHeader,
    TabDetailsInvoiceAp,
    TabTaxDetailsInvoiceAp,
    TabApplyPrepaymentInvoiceAp,
    TabStatusInvoiceAp,
  },
  computed: {
    ...mapState({
      storeAPForm: (store: any) =>
        store.invoiceApStore.formState as ApInvoiceFormState,
    }),
  },
  methods: {
    ...mapMutations({
      setAPForm: "invoiceApStore/setFormState",
    }),
  },
})
export default class CreateInvoiceAp extends Mixins(
  MNotificationVue,
  MValidationVue
) {
  @Ref() readonly form!: FormModel;

  storeAPForm!: ApInvoiceFormState;
  setAPForm!: (payload: Partial<ApInvoiceFormState>) => void;

  mode: string = Mode.CREATE;
  params: RequestQueryParamsModel = {};
  loading = {
    cancel: false,
    submit: false,
    approve: false,
    reject: false,
  };
  tabs = [
    {
      title: this.$t("lbl_detail"),
      key: "detail",
      component: "TabDetailsInvoiceAp",
    },
    {
      title: this.$t("common.detail-text", { text: this.$t("lbl_tax") }),
      key: "tax",
      component: "TabTaxDetailsInvoiceAp",
    },
    {
      title: this.$t("lbl_apply_prepayment"),
      key: "prepayment",
      component: "TabApplyPrepaymentInvoiceAp",
    },
    {
      title: this.$t("lbl_status"),
      key: "status",
      component: "TabStatusInvoiceAp",
    },
  ];

  activeTabPane = "detail";
  invoiceAPId = "";

  validationSchema = {
    source: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    branch: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    currency: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    supplier: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    supplierShipAddress: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    supplierBillAddress: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    termOfPayment: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    documentReferences: {
      required: true,
      type: "array",
      message: this.$t("lbl_validation_required_error"),
    },
    taxType: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    invoiceSupplierNo: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    invoiceDate: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    invoiceReceivedDate: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    payablesAccount: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    invoiceDescription: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    taxInvoiceDate: {
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
    products: {
      type: "array",
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
  };

  mounted(): void {
    this.mode = this.$route.meta.mode;
    if (this.$route.params.id) {
      this.invoiceAPId = this.$route.params.id;
    }
  }

  get allowToSaveDraft(): boolean {
    if (!this.storeAPForm.status) return false;
    return ALLOW_TO_SAVE_DRAFT.includes(this.storeAPForm.status);
  }

  get allowToUpdate(): boolean {
    if (!this.storeAPForm.status) return false;
    return ALLOW_TO_UPDATE.includes(this.storeAPForm.status);
  }

  get allowToApprove(): boolean {
    if (!this.storeAPForm.status) return false;
    return ALLOW_TO_APPROVE.includes(this.storeAPForm.status);
  }

  get allowToCancel(): boolean {
    if (!this.storeAPForm.status) return false;
    return ALLOW_TO_CANCEL.includes(this.storeAPForm.status);
  }

  get showSubmit(): boolean {
    return (
      (this.storeAPForm.status &&
        ALLOW_TO_SUBMIT.includes(this.storeAPForm.status)) ||
      this.isModeCreate
    );
  }

  get isDraft(): boolean {
    return this.storeAPForm.status === INVOICE_AP_STATUS.NEED_APPROVAL;
  }

  get isModeCreate(): boolean {
    return this.mode === Mode.CREATE;
  }

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }

  handleBack(): void {
    this.$router.push({
      name: "account-payables.invoice-ap.list",
      query: { ...this.$route.query },
    });
  }

  onSubmit(type: SubmitType): void {
    this.form?.validate((valid, value) => {
      // validate table
      if (!this.storeAPForm.products.length) {
        this.showNotifWarning("lbl_invoice_lines_is_mandatory");
        return;
      }

      const [isInvalid, columns] = validateTable<ApInvoiceFormProductState>(
        this.storeAPForm.products,
        REQUIRED_TABLE_COLUMN
      );

      if (isInvalid) {
        // show notif
        const message = this.doTranslateColumns(columns, TABLE_TRANSLATE_KEY);
        this.showNotifWarning("notif_column_is_mandatory", { column: message });
        return;
      }

      if (!valid) {
        const message = this.doTranslateFields(value, FORM_TRANSLATE_KEY);
        this.showNotifWarning("notif_field_is_mandatory", { field: message });
        return;
      }

      switch (type) {
        case "submit":
          if (
            this.invoiceAPId &&
            this.storeAPForm.status === INVOICE_AP_STATUS.DRAFT
          ) {
            this.handleSubmitDocument(this.invoiceAPId);
          } else if (this.invoiceAPId) {
            this.handleUpdateDocument(this.invoiceAPId);
          } else {
            this.handleSubmitNewDocument();
          }
          break;
        case "approve":
          this.handleApproveDocument(this.invoiceAPId);
          break;
        case "draft":
          if (this.invoiceAPId) {
            this.handleUpdateDocument(this.invoiceAPId);
          } else {
            this.handleSaveAsDraft();
          }
          break;
        default:
          break;
      }
    });
  }

  /**
   * handle click save as draft
   */
  async handleSaveAsDraft(): Promise<void> {
    try {
      this.loading.submit = true;
      const payload: InvoiceApCreateDto = ApInvoiceMapper.toCreateDraftDto(
        this.storeAPForm
      );
      const { documentNumber, id } = await invoiceAPServices.createApInvoice(
        payload
      );
      this.showNotifSuccess("notif_document_created_as_draft_success", {
        documentNumber,
      });
      this.$router.push({
        name: "account-payables.invoice-ap.edit",
        params: { id },
      });
    } catch (error) {
      this.showNotifError("notif_submit_fail");
    } finally {
      this.loading.submit = false;
    }
  }

  /**
   * handle click submit without existing document
   */
  async handleSubmitNewDocument(): Promise<void> {
    try {
      this.loading.submit = true;
      const payload: InvoiceApCreateDto =
        ApInvoiceMapper.toCreateNeedApprovalDto(this.storeAPForm);
      const { documentNumber, id } = await invoiceAPServices.createApInvoice(
        payload
      );
      this.showNotifSuccess("notif_document_created_as_submit_success", {
        documentNumber,
      });
      this.$router.push({
        name: "account-payables.invoice-ap.edit",
        params: { id },
      });
    } catch (error) {
      this.showNotifError("notif_submit_fail");
    } finally {
      this.loading.submit = false;
    }
  }

  /**
   * handle click submit with existing document
   * @param docId invoice ap id
   */
  async handleSubmitDocument(docId: string): Promise<void> {
    try {
      this.loading.submit = true;
      const payload: InvoiceApUpdateDto =
        ApInvoiceMapper.toUpdateNeedApprovalDto(this.storeAPForm);
      const { documentNumber, id } = await invoiceAPServices.submitApInvoice(
        docId,
        payload
      );
      this.showNotifSuccess("notif_submit_success", { documentNumber });
      this.getDetail(id);
    } catch (error) {
      this.showNotifError("notif_submit_fail");
    } finally {
      this.loading.submit = false;
    }
  }

  /**
   * handle update invoice
   */
  async handleUpdateDocument(docId: string): Promise<void> {
    try {
      this.loading.submit = true;
      const payload: InvoiceApUpdateDto = ApInvoiceMapper.toUpdateDto(
        this.storeAPForm
      );
      const { documentNumber, id } = await invoiceAPServices.updateApInvoice(
        docId,
        payload
      );
      this.showNotifSuccess("notif_update_success", { documentNumber });
      this.getDetail(id);
    } catch (error) {
      this.showNotifError("notif_update_fail");
    } finally {
      this.loading.submit = false;
    }
  }

  /**
   * handle click cancel
   * @param id invoice ap id
   */
  async handleCancelDocument(docId: string): Promise<void> {
    try {
      this.loading.cancel = true;
      const { documentNumber } = await invoiceAPServices.cancelApInvoice(docId);
      this.showNotifSuccess("notif_cancel_success", { documentNumber });
      this.$router.replace({
        name: "account-payables.invoice-ap.list",
        query: { ...this.$route.query },
      });
    } catch (error) {
      this.showNotifError("notif_cancel_fail");
    } finally {
      this.loading.cancel = false;
    }
  }

  /**
   * handle click approve
   * @param id invoice ap id
   */
  async handleApproveDocument(docId: string): Promise<void> {
    try {
      this.loading.approve = true;
      const payload: InvoiceApUpdateDto = ApInvoiceMapper.toUpdateUnpaidDto(
        this.storeAPForm
      );
      const { documentNumber } = await invoiceAPServices.approveApInvoice(
        docId,
        payload
      );
      this.showNotifSuccess("notif_approve_success", { documentNumber });
      this.$router.replace({
        name: "account-payables.invoice-ap.list",
        query: { ...this.$route.query },
      });
    } catch (error) {
      this.showNotifError("notif_approve_fail");
    } finally {
      this.loading.approve = false;
    }
  }

  /**
   * handle click reject
   * @param id invoice ap id
   */
  async handleRejectDocument(docId: string): Promise<void> {
    try {
      this.loading.reject = true;
      const { documentNumber } = await invoiceAPServices.rejectApInvoice(docId);
      this.showNotifSuccess("notif_reject_success", { documentNumber });
      this.$router.push({
        name: "account-payables.invoice-ap.list",
        query: { ...this.$route.query },
      });
    } catch (error) {
      this.showNotifError("notif_reject_fail");
    } finally {
      this.loading.reject = false;
    }
  }

  /**
   * get detail invoice ap
   * @param id invoice ap id
   */
  async getDetail(id: string): Promise<void> {
    try {
      const detail: InvoiceApResponseDetailDto =
        await invoiceAPServices.detailApInvoice(id);
      const state = ApInvoiceMapper.toFormState(detail);
      this.setAPForm(state);
    } catch (error) {
      this.showNotifError("notif_process_fail");
    }
  }
}
