













































































































































import currencyFilter from "@/filters/currency.filter";
import dateFormat from "@/filters/date.filter";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import {
  ApInvoiceFormState,
  ApInvoicePaymentDetail,
} from "@/store/account-payable/ap-invoice/types";
import {
  formatterNumber,
  formatterPercent,
  reverseFormatNumber,
  reverseFormatPercent,
} from "@/validator/globalvalidator";
import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";

type TableType = "prepaymentUsed" | "payment" | "return";

@Component({
  computed: {
    ...mapState({
      formState: (st: any) => st.invoiceApStore.formState as ApInvoiceFormState,
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
  },
  methods: {
    formatterNumber,
    formatterPercent,
    reverseFormatNumber,
    reverseFormatPercent,
  },
})
export default class TabStatusInvoiceAp extends Vue {
  formState!: ApInvoiceFormState;

  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  formRules = {
    total: {
      label: "lbl_total",
      name: "total",
      placeholder: "lbl_total_placeholder",
    },
    prepaymentUsed: {
      label: "lbl_prepayment_used",
      name: "prepaymentUsed",
      placeholder: "lbl_prepayment_used_placeholder",
    },
    remainingInvoiceAmount: {
      label: "lbl_remaining_invoice_amount",
      name: "remainingInvoiceAmount",
      placeholder: "lbl_remaining_invoice_amount_placeholder",
    },
    payment: {
      label: "lbl_payment",
      name: "payment",
    },
    return: {
      label: "lbl_returns",
      name: "return",
      placeholder: "lbl_returns_placeholder",
    },
  };
  visibleModal = false;
  titleModal: TableType = "prepaymentUsed";
  dataSourcePrepaymentUsed: ApInvoicePaymentDetail[] = [];
  dataSourceApPayment: ApInvoicePaymentDetail[] = [];
  dataSourceReturn = [];

  columnsTablPrepaymentUsed = [
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "transactionDate",
      key: "invoiceDate",
      width: 170,
      customRender: text => dateFormat(text),
    },
    {
      title: this.$t("lbl_invoice_number"),
      dataIndex: "docNumber",
      key: "invoiceNumber",
      width: 170,
    },
    {
      title: this.$t("lbl_prepayment_used_amount"),
      dataIndex: "amount",
      key: "prepaymentUsedAmount",
      width: 170,
      customRender: text => currencyFilter(text),
    },
  ];
  columnsTableApPayment = [
    {
      title: this.$t("lbl_payment_date"),
      dataIndex: "transactionDate",
      key: "paymentDate",
      width: 170,
      customRender: text => dateFormat(text),
    },
    {
      title: this.$t("lbl_payment_number"),
      dataIndex: "docNumber",
      key: "paymentNumber",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_amount"),
      dataIndex: "amount",
      key: "paymentAmount",
      width: 170,
      customRender: text => currencyFilter(text),
    },
  ];
  columnsTableReturn = [
    {
      title: this.$t("lbl_return_date"),
      dataIndex: "returnDate",
      key: "returnDate",
      width: 170,
      customRender: text => dateFormat(text),
    },
    {
      title: this.$t("lbl_return_number"),
      dataIndex: "returnNumber",
      key: "returnNumber",
      width: 170,
    },
    {
      title: this.$t("lbl_return_amount"),
      dataIndex: "returnAmount",
      key: "returnAmount",
      width: 170,
      customRender: text => currencyFilter(text),
    },
  ];

  wrapper = {
    wrapperCol: {
      span: 5,
    },
    labelCol: {
      span: 4,
    },
  };

  handleCloseModal() {
    this.visibleModal = false;
  }

  handleClickMore(form: TableType): void {
    this.visibleModal = true;
    switch (form) {
      case "prepaymentUsed":
        this.setTablePrepayment();
        break;
      case "payment":
        this.setTableApPayment();
        break;
      case "return":
        this.titleModal = form;
        this.dataSourceReturn = [];
        break;
      default:
        break;
    }
  }

  setTablePrepayment(): void {
    this.titleModal = "prepaymentUsed";
    this.dataSourcePrepaymentUsed = this.formState.prePaymentUsedDetails;
  }

  setTableApPayment(): void {
    this.titleModal = "payment";
    this.dataSourceApPayment = this.formState.paymentDetails;
  }
}
