var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_invoice_ap") } },
    [
      _c(
        "a-form-model",
        _vm._b(
          {
            ref: "form",
            attrs: {
              rules: _vm.validationSchema,
              model: _vm.storeAPForm,
              "label-align": "left"
            }
          },
          "a-form-model",
          _vm.formItemLayout,
          false
        ),
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [_c("a-col", { attrs: { span: 24 } }, [_c("InvoiceApHeader")], 1)],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-tabs",
                    {
                      attrs: { type: "card" },
                      model: {
                        value: _vm.activeTabPane,
                        callback: function($$v) {
                          _vm.activeTabPane = $$v
                        },
                        expression: "activeTabPane"
                      }
                    },
                    _vm._l(_vm.tabs, function(tab) {
                      return _c(
                        "a-tab-pane",
                        {
                          key: tab.key,
                          attrs: { "force-render": tab.key === "tax" }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "tab" }, slot: "tab" },
                            [
                              _vm._v(" " + _vm._s(tab.title) + " "),
                              _c("a-icon", {
                                staticStyle: { color: "red" },
                                attrs: { type: "tag" }
                              })
                            ],
                            1
                          ),
                          _c(tab.component, { tag: "component" })
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleBack } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                      ]),
                      (_vm.isModeCreate || _vm.allowToSaveDraft) &&
                      _vm.$can("create", "invoice-ap") &&
                      _vm.$can("update", "invoice-ap")
                        ? _c(
                            "a-button",
                            {
                              attrs: { loading: _vm.loading.submit },
                              on: {
                                click: function($event) {
                                  return _vm.onSubmit("draft")
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_save_as_draft")) + " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.allowToCancel && _vm.$can("cancel", "invoice-ap")
                        ? [
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: _vm.$t("pop_confirmation"),
                                  "ok-text": _vm.$t("lbl_yes"),
                                  "cancel-text": _vm.$t("lbl_no")
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.handleCancelDocument(
                                      _vm.invoiceAPId
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      loading: _vm.loading.cancel
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("lbl_cancel")) + " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm.showSubmit &&
                      _vm.$can("update", "invoice-ap") &&
                      _vm.$can("create", "invoice-ap")
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.submit
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onSubmit("submit")
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                          )
                        : _vm._e(),
                      _vm.allowToApprove && _vm.$can("approve", "invoice-ap")
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.approve
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onSubmit("approve")
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_approve")) + " ")]
                          )
                        : _vm._e(),
                      _vm.allowToApprove && _vm.$can("approve", "invoice-ap")
                        ? [
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: _vm.$t("pop_confirmation"),
                                  "ok-text": _vm.$t("lbl_yes"),
                                  "cancel-text": _vm.$t("lbl_no")
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.handleRejectDocument(
                                      _vm.invoiceAPId
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      loading: _vm.loading.reject
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("lbl_reject")) + " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }